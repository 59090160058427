import { EventData } from '../interfaces/events.interface';
import { getEnv } from '../utils/env';

const UA_TRACKING_ID = getEnv('REACT_APP_UNIVERSAL_ANALYTICS');

const create = (trackingId: string): void => {
  window.ga('create', trackingId, 'auto');
};

const setUser = (userId: string): void => {
  window.ga('set', 'userId', userId);
};

const pageView = (): void => {
  window.ga('send', 'pageview');
};

const init = (): void => {
  create(UA_TRACKING_ID);
  pageView();
};

const logEvent = (
  { action, category, label, value }: EventData,
  _payload?: Record<string, unknown>,
): void => {
  window.ga('send', 'event', {
    hitType: 'event',
    eventCategory: category,
    eventAction: action,
    eventLabel: label,
    eventValue: value,
  });
};

const Universal = {
  create,
  setUser,
  pageView,
  init,
  logEvent,
};

export default Universal;
