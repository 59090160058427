export const PLANS = {
  LIST: `api/v3/payments/system/plans/{0}`,
  DETAIL: `api/v3/payments/plan/detail`,
  AUTH_LIST: `api/v3/payments/plans/?region={0}&payment_type={1}`,
  SIGNED: `api/v3/payments/plan/?region={0}`,
  BUY_PLAN: `api/v3/payments/plan/buy/{0}/`,
  MIGRATE_PLAN: `api/v3/payments/plans/migrate/`,
};

export const CREDIT_CARD = {
  GET: `api/v2/payments/credit-card`,
  SIGNUP: `api/v3/payments/credit-card/tokenize/?region={0}`,
};

export const SIGNUP = {
  NEW: `api/v1/accounts/signup/`,
  ADDRESS: `api/v2/accounts/address/?region={0}`,
};

export const REWARDS = {
  OPEN: `api/v2/benefits/open?region={0}`,
  EXPIRED: `api/v2/benefits/expired?region={0}`,
  WITHDRAWN: `api/v2/benefits/withdrawals?region={0}&page_number={1}&count={2}`,
};

export const VALIDATIONS = {
  USER: `api/v3/accounts/user/validation/`,
};

export const ACCESS = {
  LOGIN: {
    EMAIL: `api/v1/accounts/signin/`,
    DOCUMENT: `api/v1/accounts/signin/document`,
  },
  RECOVERY_PASSWORD: `api/v1/accounts/recovery-password/`,
  TOKEN: {
    GENERATE: `api/v2/accounts/generate-sms-code/`,
    VALIDATE: `api/v1/accounts/validate-sms-code/`,
  },
  CHANGE_PASSWORD: `api/v1/accounts/change-password/`,
};

export const CAMPAIGNS = {
  BANNER: `api/v2/campaign/{0}/?public_id={1}`,
};

export const QRCODE = {
  GENERATE: `api/v3/system/qrcode/`,
};

export const STATION = {
  AVAILABILITY: `api/v2/system/bikes-availability/?region={0}`,
};

export const BIKECODE = {
  GET: `api/v3/system/support-buffer/bike/?region={0}&code={1}`,
};
export const UNLOCKBIKE = {
  POST: `api/v3/system/support-buffer/bike/unlock/?region={0}`,
};
export const LOCKBIKE = {
  POST: `api/v3/system/support-buffer/bike/lock/?region={0}`,
};

export const ACTIONBUTTONS = {
  GET: `api/v1/content/hub/?region={0}`,
};

export const PENALTY = {
  PATCH: `api/v1/penalty/cancel-auto-migration`,
  FUTURE_PLAN: {
    GET: `api/v1/penalty/plan/?region={0}`,
  },
};

export const SEND_WPP = {
  POST: 'api/v3/accounts/send-wpp',
};
