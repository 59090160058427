import { createTheme } from '@mui/material';
import { palette } from './palette';

declare module '@mui/material/styles/createPalette' {
  export interface TypeBackground {
    ice: string;
    salmon: string;
    white: string;
    errorLight: string;
    red: string;
  }

  export interface Palette {
    redWarning: { main: string };
  }

  export interface PaletteOptions {
    redWarning: { main: string };
  }
}

declare module '@mui/material/styles' {
  interface TypographyVariants {
    // title
    h1: React.CSSProperties;
    h2: React.CSSProperties;
    h3: React.CSSProperties;
    h4: React.CSSProperties;
    h5: React.CSSProperties;
    // paragraph
    p1: React.CSSProperties;
    p2: React.CSSProperties;
    p3: React.CSSProperties;
    p4: React.CSSProperties;
    p5: React.CSSProperties;
    p6: React.CSSProperties;
    // subtitle
    s1: React.CSSProperties;
    s2: React.CSSProperties;
    // button
    b1: React.CSSProperties;
    b2: React.CSSProperties;
    b3: React.CSSProperties;
    b4: React.CSSProperties;
    // display
    d1: React.CSSProperties;
    d2: React.CSSProperties;
    d3: React.CSSProperties;
    d4: React.CSSProperties;
    d5: React.CSSProperties;
    // form
    f1: React.CSSProperties;
    f2: React.CSSProperties;
    f3: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    // title
    h1?: React.CSSProperties;
    h2?: React.CSSProperties;
    h3?: React.CSSProperties;
    h4?: React.CSSProperties;
    h5?: React.CSSProperties;
    // paragraph
    p1?: React.CSSProperties;
    p2?: React.CSSProperties;
    p3?: React.CSSProperties;
    p4?: React.CSSProperties;
    p5?: React.CSSProperties;
    p6?: React.CSSProperties;
    // subtitle
    s1?: React.CSSProperties;
    s2?: React.CSSProperties;
    // button
    b1?: React.CSSProperties;
    b2?: React.CSSProperties;
    b3?: React.CSSProperties;
    b4?: React.CSSProperties;
    // display
    d1?: React.CSSProperties;
    d2?: React.CSSProperties;
    d3?: React.CSSProperties;
    d4?: React.CSSProperties;
    d5?: React.CSSProperties;
    // form
    f1?: React.CSSProperties;
    f2?: React.CSSProperties;
    f3?: React.CSSProperties;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    // title
    h1: true;
    h2: true;
    h3: true;
    h4: true;
    h5: true;
    h6: false;
    // paragraph
    p1: true;
    p2: true;
    p3: true;
    p4: true;
    p5: true;
    p6: true;
    // subtitle
    s1: true;
    s2: true;
    // button
    b1: true;
    b2: true;
    b3: true;
    b4: true;
    // display
    d1: true;
    d2: true;
    d3: true;
    d4: true;
    d5: true;
    // form
    f1: true;
    f2: true;
    f3: true;
  }
}

const display = {
  d1: {
    fontSize: '40px',
    fontWeight: 500,
    lineHeight: '50px',
    letterSpacing: '0em',
  },
  d2: {
    fontSize: '40px',
    fontWeight: 300,
    lineHeight: '50px',
    letterSpacing: '0em',
  },
  d3: {
    fontSize: '30px',
    fontStyle: 'italic',
    fontWeight: 1,
    lineHeight: '30px',
    letterSpacing: '0.25px',
  },
  d4: {
    fontSize: '20px',
    fontWeight: 700,
    lineHeight: '28px',
    letterSpacing: '0.15px',
  },
  d5: {
    fontSize: '20px',
    fontWeight: 500,
    lineHeight: '28px',
    letterSpacing: '10px',
  },
};

const title = {
  h1: {
    fontSize: '20px',
    fontWeight: 500,
    lineHeight: '28px',
    letterSpacing: '0.15px',
  },
  h2: {
    fontSize: '18px',
    fontWeight: 500,
    lineHeight: '25.5px',
    letterSpacing: '0.0015em',
  },
  h3: {
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '22.4px',
    letterSpacing: '0.15px',
  },
  h4: {
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '20px',
    letterSpacing: '0.1px',
  },
  h5: {
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '15px',
    letterSpacing: '0.65px',
  },
};

const paragraph = {
  p1: {
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '20px',
    letterSpacing: '0.25px',
  },
  p2: {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '21px',
    letterSpacing: '0.25px',
  },
  p3: {
    fontSize: '16px',
    fontWeight: 300,
    lineHeight: '22.5px',
    letterSpacing: '0.25px',
  },
  p4: {
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '20px',
    letterSpacing: '0.25px',
  },
  p5: {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
    letterSpacing: '0.25px',
  },
  p6: {
    fontSize: '14px',
    fontWeight: 300,
    lineHeight: '20px',
    letterSpacing: '0.25px',
  },
};

const subTitle = {
  s1: {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
    letterSpacing: '0.1px',
  },
  s2: {
    fontSize: '12px',
    fontWeight: 300,
    lineHeight: '20px',
    letterSpacing: '0.4px',
  },
};
const form = {
  f1: {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '20px',
    letterSpacing: '0.1px',
  },
  f2: {
    fontSize: '16px',
    fontWeight: 300,
    lineHeight: '20px',
    letterSpacing: '0.1px',
  },
  f3: {
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '15px',
    letterSpacing: '0.15px',
  },
};

const button = {
  b1: {
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '20px',
    letterSpacing: '0.1px',
  },
  b2: {
    fontSize: '16px',
    fontWeight: 300,
    lineHeight: '20px',
    letterSpacing: '0.1px',
  },
  b3: {
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '20px',
    letterSpacing: '0.1px',
  },
  b4: {
    fontSize: '14px',
    fontWeight: 300,
    lineHeight: '20px',
    letterSpacing: '0.1px',
  },
};

export default createTheme({
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundColor: '#ffffff',
          fontSize: '12px',
        },
        '.MuiBackdrop-root': {
          backgroundColor: 'rgba(0,0,0,.32)',
        },
      },
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          d1: 'p',
          d2: 'p',
          d3: 'p',
          d4: 'p',
          d5: 'p',
          s1: 'p',
          s2: 'p',
          p1: 'p',
          p2: 'p',
          p3: 'p',
          p4: 'p',
          p5: 'p',
          p6: 'p',
          f1: 'span',
          f2: 'span',
          f3: 'span',
          b1: 'span',
          b2: 'span',
          b3: 'span',
          b4: 'span',
        },
      },
    },
  },
  palette,
  typography: {
    fontFamily: 'Sul Sans',
    caption: {
      fontSize: '9px',
      lineHeight: '14px',
    },
    ...display,
    ...title,
    ...subTitle,
    ...paragraph,
    ...form,

    ...button,
    subtitle1: {
      fontSize: '14px',
    },
    subtitle2: {
      fontSize: '12px',
    },
    body1: {
      fontSize: '16px',
    },
    body2: {
      fontSize: '12px',
    },
  },
  shape: {
    borderRadius: 4,
  },
  spacing: 8,
});
