import { useNavigate } from 'react-router-dom';
import { Close, NavigateBefore } from '@mui/icons-material';
import * as S from './style';

import { AppRoute } from '../../../constants/routes.const';
import { backToApp } from '../../../utils/route';

const goBackRoute = -1;

type PageAction = AppRoute | (() => void);
interface IHeaderProps {
  title: string;
  goBack?: boolean;
  exitApp?: boolean;
  backRoute?: PageAction;
  closePage?: PageAction;
  steps?: {
    current: number;
    total: number;
  };
}

export enum NavbarTestId {
  navbar = 'navbar',
  navbarReturn = 'navbar-return',
  navbarTitle = 'navbar-title',
  navbarClose = 'navbar-close',
}

const NavBar = ({
  title,
  closePage,
  backRoute,
  goBack,
  steps,
  exitApp,
}: IHeaderProps): JSX.Element => {
  const navigate = useNavigate();

  const handleClick = (pageAction: PageAction | undefined): void => {
    if (exitApp) return backToApp();
    if (!pageAction) return navigate(goBackRoute);
    if (typeof pageAction === 'string') {
      navigate(pageAction);
      return;
    }

    pageAction();
  };

  return (
    <>
      <S.NavBar data-testid={NavbarTestId.navbar}>
        <S.IconContainer>
          {!!(goBack || backRoute || exitApp) && (
            <NavigateBefore
              data-testid={NavbarTestId.navbarReturn}
              color="primary"
              onClick={(): void => handleClick(backRoute)}
            />
          )}
        </S.IconContainer>
        <S.Title data-testid={NavbarTestId.navbarTitle}>{title}</S.Title>
        <S.IconContainer>
          {!!closePage && (
            <Close
              data-testid={NavbarTestId.navbarClose}
              color="primary"
              onClick={(): void => handleClick(closePage)}
            />
          )}
        </S.IconContainer>
      </S.NavBar>
      {steps && <S.Steps data-testid="steps" {...steps} />}
    </>
  );
};

export default NavBar;
