export enum QueryKeys {
  pswdToken = 'pswdToken',
  SendToken = 'sendToken',
  addCard = 'addCard',
  banners = 'banners',
  bikeStations = 'bikeStations',
  getCard = 'getCard',
  plans = 'plans',
  planDetail = 'planDetail',
  qrCode = 'qrCode',
  pendingRewards = 'pendingRewards',
  expiredRewards = 'expiredRewards',
  withdrawnRewards = 'withdrawnRewards',
  userData = 'userData',
  userPlan = 'userPlan',
  futurePlan = 'futurePlan',
  actionButtons = 'actionButtons',
}
