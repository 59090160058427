import { Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../atoms';
import * as S from './styles';

export interface ISwipeableProp {
  openProp: boolean;
  onClose: () => void;
  CONTENT: {
    Title: string;
    List: {
      id: number;
      message: string;
    }[];
  };
  Route: string;
  anchor: 'bottom' | 'left' | 'right' | 'top';
  ButtonText: string;
  showParagraphCircle?: boolean;
}

const Swipeable = ({
  openProp,
  onClose,
  CONTENT,
  Route,
  anchor,
  ButtonText,
  showParagraphCircle = false,
}: ISwipeableProp): JSX.Element => {
  const navigate = useNavigate();

  return (
    <S.Swipeable anchor={anchor} open={openProp} onClose={onClose} onOpen={(): void => undefined}>
      <S.Container
        onClick={onClose}
        onKeyDown={onClose}
        role="presentation"
        data-testid="swipeable"
      >
        <Box display="flex" justifyContent="center" marginTop={1}>
          <S.PushIcon />
        </Box>
        <Box
          paddingTop={4}
          paddingBottom={3}
          display="flex"
          justifyContent="center"
          alignItems={showParagraphCircle ? 'flex-start' : 'center'}
          flexDirection="column"
          paddingLeft={showParagraphCircle ? 2 : 5}
          paddingRight={showParagraphCircle ? 2 : 5}
        >
          <Box display="flex" flexDirection="row" alignItems="center" gap={3} paddingLeft={1}>
            <div>
              <S.ParagraphCircleIcon />
            </div>
            <Typography variant="h3">{CONTENT.Title}</Typography>
          </Box>
          <S.CustomDivider />

          {CONTENT.List.map(item => (
            <Box
              key={item.id}
              display="flex"
              flexDirection="row"
              alignItems="center"
              marginTop={5}
              gap={3}
              paddingLeft={1}
            >
              {showParagraphCircle && (
                <div>
                  <S.ParagraphCircleIcon />
                </div>
              )}

              <Typography textAlign={showParagraphCircle ? 'initial' : 'center'} variant="p2">
                {item.message}
              </Typography>
            </Box>
          ))}
          <Box marginTop={4} width="100%">
            <Button color="primary" fullWidth onClick={(): void => navigate(Route)}>
              {ButtonText}
            </Button>
          </Box>
        </Box>
      </S.Container>
    </S.Swipeable>
  );
};

export default Swipeable;
