export const palette = {
  primary: { main: '#EA1D2C', light: 'rgba(234, 29, 44, 0.2)' },
  success: { main: '#43A047', light: '#50A773' },
  warning: { main: '#3E3E3E' },
  error: { main: '#82166E' },
  redWarning: { main: '#E02020' },
  text: {
    primary: '#3E3E3E',
    secondary: '#535161',
  },
  background: {
    ice: '#FCEBEA',
    errorLight: '#FFF1EE',
    salmon: '#FFF1EE',
    white: '#FFFFFF',
    red: '#EF5753',
  },
};
