import { AppRoute, ROUTES } from '../constants/routes.const';
import { URLS } from '../constants/urls.const';
import { MessageTypes } from '../pages/InfoView/messages.const';

export const messageRoute = (messageType: MessageTypes): AppRoute => {
  return `${ROUTES.INFO_VIEW}/${messageType}`;
};

export const ensurePrefixedRoute = (url: string): string =>
  url.charAt(0) === '/' ? url : `/${url}`;

interface NewUrl {
  href: string;
  target: string;
  rel: string;
}

export const openNewUrl = (href: string): NewUrl => ({
  href,
  target: '_blank',
  rel: 'noopener noreferrer',
});

export const backToApp = (): void => {
  window.location.href = URLS.EXIT_WEBVIEW;
};
