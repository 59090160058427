import Analytics from './analytics.service';
import Universal from './universal.service';

import { EventData, EventHandler } from '../interfaces/events.interface';
import { AnalyticsService } from '../modules/temAnalytics';

export const eventHandlers: EventHandler[] = [Analytics, Universal];

const logEvent = (eventData: EventData, payload?: Record<string, unknown>): void => {
  eventHandlers.forEach(handler => handler.logEvent(eventData, payload));
  AnalyticsService.instance.emitEvent({
    name: eventData.action,
    params: payload,
  });
};

const init = (): void => eventHandlers.forEach(handler => handler.init());

const Events = { logEvent, init };

export default Events;
