export const HEADER_TITLE = {
  ACCOUNT: 'Minha Conta',
  ACCOUNT_SETTINGS: 'CONFIGURAÇÕES',
  ADDRESS: 'Cadastro iFood pedal',
  BIKE_STATIONS: 'Pontos de Apoio',
  HOME: 'Bikes Tembici',
  LOGIN: 'Acesso',
  MIGRATE_PLAN: 'Troca de Plano',
  NEW_PASSWORD: 'Acesso',
  PAYMENT_METHOD: 'Selecione a forma de pagamento',
  PLAN_SELECTION: 'Confirme seu plano',
  PROGRAM_DETAILS: 'Conheça o ifood pedal',
  PURCHASE_PLAN: 'Resumo da compra',
  RECOVERY_PASSWORD: 'Recuperação de senha',
  RETURN_BIKE: 'DEVOLUÇÃO DE BIKE',
  REWARDS: 'Recompensas',
  SIGNUP: 'Cadastro iFood pedal',
  SIGNUP_CARD: 'Cadastrar cartão de crédito',
  SUPPORT: 'Fale com a Tembici',
  TERMS: 'Termos e Condições',
  UNLOCK_BIKE: 'Desbloqueio',
  UNLOCK_BIKE_V2: 'Retirada de Bike',
  UPDATE_CARD: 'Atualizar cartão de crédito',
  UPDATE_PASSWORD: 'Alteração de senha',
  USER_PLAN: 'Meu Plano',
};
