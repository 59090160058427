export const ACTIONS = {
  ACCESS_HUB: 'access_hub',
  ACCOUNT_VIEW: 'account_view',
  ADDRESS_CLICK: 'address_click',
  ADDRESS_FAIL: 'address_fail',
  ADDRESS_NUMBER: 'address_number',
  ADDRESS_NUMBER_COMP: 'address_number_comp',
  ADDRESS_SUCCESS: 'address_success',
  ADDRESS_ZIPCODE: 'address_zipcode',
  BANNER_CLICK: 'banner_click',
  BANNER_VIEW: 'banner_view',
  BIKE_CODE_SUCCESS: 'bike_code_success',
  BIKE_CODE_FAIL: 'bike_code_fail',
  BUY_PLAN: 'buy_plan',
  CALL_CHANGE_NUMBER: 'call_change_number',
  CANCEL_PLAN_CLICK: 'cancel_plan_click',
  CANCEL_PLAN_CLOSE: 'cancel_plan_close',
  CANCEL_PLAN_CONFIRMATION: 'cancel_plan_confirmation',
  CHANGE_CREDITCARD_CLICK: 'change_creditcard_click',
  CHANGE_PASSWORD_CLICK: 'change_password_click',
  CLICK_MENU: 'click_menu',
  CODE_GENERATE_CLICK: 'code_generate_click',
  CODE_GENERATE_FAIL: 'code_generate_fail',
  CODE_GENERATE_SUCCESS: 'code_generate_success',
  CREDITCARD_CLICK: 'creditcard_click',
  CREDITCARD_FAIL: 'creditcard_fail',
  CREDITCARD_SAVE_CLICK: 'creditcard_save_click',
  CREDITCARD_SUCCESS: 'creditcard_success',
  ELETRIC_BIKE_MAPS_CLICK: 'eletric_bike_maps_click',
  FORGET_PASSWORD_CLICK: 'forget_password_click',
  LANDING_PLAN_CLICK: 'landing_plan_click',
  LOGIN_CLICK: 'login_click',
  LOGIN_FAIL: 'login_fail',
  LOGIN_SUCCESS: 'login_success',
  NEW_PASSWORD_CLICK: 'new_password_click',
  NEW_PASSWORD_FAIL: 'new_password_fail',
  NEW_PASSWORD_SUCCESS: 'new_password_success',
  PAYMENT_EXTERNAL_CLICK: 'payment_external_click',
  PLAN_CLICK: 'plan_click',
  PLAN_MIGRATE_CLICK: 'plan_migrate_click',
  PLAN_MIGRATE_CREDIT_CARD_CLICK: 'plan_migrate_credit_card_click',
  PLAN_MIGRATE_CREDIT_CARD_FAIL: 'plan_migrate_credit_card_fail',
  PLAN_MIGRATE_CREDIT_CARD_SUCCESS: 'plan_migrate_credit_card_success',
  PLAN_MIGRATE_FAIL: 'plan_migrate_fail',
  PLAN_MIGRATE_PAYMENT_EXTERNAL_CLICK: 'plan_migrate_payment_external_click',
  PLAN_MIGRATE_PAYMENT_EXTERNAL_FAIL: 'plan_migrate_payment_external_fail',
  PLAN_MIGRATE_PAYMENT_EXTERNAL_SUCCESS: 'plan_migrate_payment_external_success',
  PLAN_MIGRATE_SUCCESS: 'plan_migrate_success',
  PLAN_PURCHASE_CLICK: 'plan_purchase_click',
  PLAN_PURCHASE_CREDIT_CARD_CLICK: 'plan_purchase_credit_card_click',
  PLAN_PURCHASE_CREDIT_CARD_FAIL: 'plan_purchase_credit_card_fail',
  PLAN_PURCHASE_CREDIT_CARD_SUCCESS: 'plan_purchase_credit_card_success',
  PLAN_PURCHASE_FAIL: 'plan_purchase_fail',
  PLAN_PURCHASE_PAYMENT_EXTERNAL_CLICK: 'plan_purchase_payment_external_click',
  PLAN_PURCHASE_PAYMENT_EXTERNAL_FAIL: 'plan_purchase_payment_external_fail',
  PLAN_PURCHASE_PAYMENT_EXTERNAL_SUCCESS: 'plan_purchase_payment_external_success',
  PLAN_PURCHASE_SUCCESS: 'plan_purchase_success',
  SPECIAL_PLAN_PURCHASE_CLICK: 'special_plan_purchase_click',
  SPECIAL_PLAN_PURCHASE_FAIL: 'special_plan_purchase_fail',
  SPECIAL_PLAN_PURCHASE_SUCCESS: 'special_plan_purchase_success',
  RECOVER_PASSWORD_VIEW: 'recover_password_view',
  RECOVER_PASSWORD_SEND_SMS: 'recover_password_send_sms',
  RECOVER_PASSWORD_SEND_SMS_FAIL: 'recover_password_send_sms_fail',
  RECOVER_PASSWORD_SEND_SMS_SUCCESS: 'recover_password_send_sms_success',
  RESEND_WHATSAPP: 'resend_whatsapp',
  RESEND_WHATSAPP_VIEW: 'resend_whatsapp_view',
  RESEND_WHATSAPP_CLICK: 'resend_whatsapp_click',
  RESEND_WHATSAPP_LINK_SUCCESS: 'resend_whatsapp_link_success',
  RESEND_WHATSAPP_LINK_FAIL: 'resend_whatsapp_link_fail',
  REWARDS_CLICK: 'rewards_click',
  REWARDS_VIEW: 'rewards_view',
  REWARD_CLICK: 'reward_click',
  SELECT_PLAN: 'select_plan',
  SIGNUP_CLICK: 'signup_click',
  SIGNUP_DOB: 'signup_dob',
  SIGNUP_FAIL: 'signup_fail',
  SIGNUP_NAME: 'signup_name',
  SIGNUP_PASSWORD: 'signup_password',
  SIGNUP_PASSWORD_CONFIRMED: 'signup_password_confirmed',
  SIGNUP_START: 'signup_start',
  SIGNUP_STEP_1_VIEW: 'signup_step-1_view',
  SIGNUP_STEP_2_VIEW: 'signup_step-2_view',
  SIGNUP_SUCCESS: 'signup_success',
  SIGNUP_SUCCESS_VIEW: 'signup_success_view',
  SINGLE_REWARD_VIEW: 'single_reward_view',
  START_MIGRATE_CLOSE: 'start_migrate_close',
  START_MIGRATE_CONFIRMATION: 'start_migrate_confirmation',
  TYC_CLICK: 'tyc_click',
  TYC_READ: 'tyc_read',
  UPDATE_ADDRESS_CLICK: 'update_address_click',
  UPDATE_ADDRESS_INFO_VIEW: 'update_address_info_view',
  UPDATE_ADDRESS_SUCCESS_VIEW: 'update_address_success_view',
  UPDATE_ADDRESS_VIEW: 'update_address_view',
  VALIDATE_PASSWORD_SMS_TOKEN_CLICK: 'validate_password_sms_token_click',
  VALIDATE_PASSWORD_SMS_TOKEN_FAIL: 'validate_password_sms_token_fail',
  VALIDATE_PASSWORD_SMS_TOKEN_SUCCESS: 'validate_password_sms_token_success',
  HOME_VIEW: 'home_view',
  CANCEL_PLAN_VIEW: 'cancel_plan_view',
  BIKE_STATIONS_VIEW: 'bike_stations_view',
  LOGIN_VIEW: 'login_view',
  MIGRATE_PLAN_VIEW: 'migrate_plan_view',
  PLAN_ENTREGADOR_CANCELAR_CARTAO: 'plan_entregador_cancelar_cartao',
  PLAN_ENTREGADOR_CANCELAR_CARTAO_SUCCESS: 'plan_entregador_cancelar_cartao_success',
  PLAN_ENTREGADOR_CANCELAR_CARTAO_FAIL: 'plan_entregador_cancelar_cartao_fail',
  PLAN_ENTREGADOR_OK: 'plan_entregador_ok',
  PLAN_ENTREGADOR_VIEW: 'plan_entregador_view',
  PURCHASE_PLAN_VIEW: 'purchase_plan_view',
  PURCHASE_SPECIAL_PLAN_VIEW: 'purchase_special_plan_view',
  PROGRAM_DETAILS_VIEW: 'program_details_view',
  PLAN_SELECTION_VIEW: 'plan_selection_view',
  PAYMENT_METHOD_VIEW: 'payment_method_view',
  ADD_CARD_VIEW: 'add_card_view',
  ACCOUNT_SETTINGS_VIEW: 'account_settings_view',
  MIGRATE_DISCLAIMER_VIEW: 'migrate_disclaimer_view',
  NEW_PASSWORD_VIEW: 'new_password_view',
  PASSWORD_TOKEN_ACCOUNT_VIEW: 'password_token_account_view',
  PASSWORD_TOKEN_LOGIN_VIEW: 'password_token_login_view',
  ACCOUNT_CLIENT_PLAN_VIEW: 'account_client_plan_view',
  LOCK_BIKE_FAIL: 'lock_bike_fail',
  LOCK_BIKE_SUCCESS: 'lock_bike_success',
  LOCK_BIKE_CLICK: 'lock_bike_click',
  LOCK_BIKE_VIEW: 'lock_bike_view',
  UNLOCK_BIKE_QRCODE_CLICK: 'unlock_bike_qrcode_click',
  UNLOCK_BIKE_VIEW: 'unlock_bike_view',
  UNLOCK_BIKE_SUCCESS: 'unlock_bike_success',
  UNLOCK_BIKE_ID_SUCCESS: 'unlock_bike_id_success',
  UNLOCK_BIKE_FAIL: 'unlock_bike_fail',
  UNLOCK_BIKE_ID_FAIL: 'unlock_bike_id_fail',
  UNLOCK_BIKE_CONFIRM_VIEW: 'unlock_bike_confirm_view',
  UNLOCK_BIKE_CONFIRM_CLICK: 'unlock_bike_confirm_click',
  UNLOCK_BIKE_SUPORT_CLICK: 'unlock_bike_suport_click',
  UNLOCK_BIKE_NO_LIMIT_VIEW: 'unlock_bike_no_limit_view',
  UNLOCK_BIKE_ERROR_ID_VIEW: 'unlock_bike_error_id_view',
  WARNING_PLAN_ENTREGADOR: 'warning_plan_entregador',
  WARNING_LIST_PAGE_VIEW: 'warning_list_page_view',
  WARNING_PLAN_ENTREGADOR_VIEWMORE: 'warning_plan_entregador_viewMore',
} as const;

export type EventActionTypes = keyof typeof ACTIONS;

export const CATEGORIES = {
  ADDRESS: 'address',
  BIKE_CODE: 'bike_code',
  CLICK: 'click',
  CODE_GENERATE: 'code_generate',
  CREDITCARD: 'creditcard',
  FORGET_PASSWORD: 'forget_password',
  LOGIN: 'login',
  PAGE: 'page',
  PLAN_MIGRATE: 'plan_migrate',
  PLAN_PURCHASE: 'plan_purchase',
  RECOVER_PASSWORD: 'recover_password',
  RESEND_WHATSAPP: 'resend_whatsapp',
  SPECIAL_PLAN_PURCHASE: 'special_plan_purchase',
  SIGNUP: 'signup',
  LOCK_BIKE: 'lock_bike',
  UNLOCK_BIKE: 'unlock_bike',
  PENALTY: 'PENALTY',
} as const;

export const LABELS = {
  FAIL: 'Fail',
  SUCCESS: 'Success',
} as const;

export const EVENTS = {
  ACTIONS,
  CATEGORIES,
  LABELS,
} as const;
