import { EventData } from '../interfaces/events.interface';
import { getEnv } from '../utils/env';

const GA_TRACKING_ID = getEnv('REACT_APP_GOOGLE_ANALYTICS');

const init = (): void => {
  if (window?.gtag) {
    window.gtag('config', GA_TRACKING_ID);
  }
};

const pageview = (url: string): void => {
  if (window?.gtag) {
    window.gtag('config', GA_TRACKING_ID, { page_path: url });
  }
};

const logEvent = (
  { action, category, label, value }: EventData,
  _payload?: Record<string, unknown>,
): void => {
  if (window?.gtag) {
    window.gtag('event', action, {
      event_category: category,
      event_label: label,
      value,
    });
  }
};

const setUser = (userId: string): void => {
  if (window?.gtag) {
    window.gtag('config', GA_TRACKING_ID, { user_id: userId });
  }
};

const Analytics = {
  init,
  pageview,
  logEvent,
  setUser,
};

export default Analytics;
