import { getEnv } from '../utils/env';

const ZENDESK_ID = getEnv('REACT_APP_ZENDESK_ID');

export const CONTA_CLIENTE = {
  CONTA_CLIENTE: '/conta-cliente',
  ATUALIZACAO_CARTAO: '/conta-cliente/atualizacao-cartao',
  CONFIGURACAO_CONTA: '/conta-cliente/configuracao',
  PLANO_CLIENTE: '/conta-cliente/plano',
  CONTA_RECOMPENSA: '/conta-cliente/recompensas',
};

export const ROUTES = {
  CADASTRO_CARTAO: '/cadastro/cartao',
  CADASTRO_CLIENTE: '/cadastro',
  CANCELAR_PLANO: '/cancelar-plano',
  ENDERECO: '/endereco',
  ESQUECEU_SENHA: '/esqueceu-senha',
  GERAR_TOKEN_CONTA: '/gerar-token-conta',
  GERAR_TOKEN_LOGIN: '/gerar-token-login',
  HUB: '/hub',
  INFO_VIEW: '/info-view',
  LOGIN: '/login',
  MAIORES_DETALHES: '/maiores-detalhes',
  MELHOR_COMPRA: '/melhor-compra',
  MIGRAR_PLANO: '/resumo-compra/migracao',
  NOVA_SENHA: '/nova-senha',
  PLANOS: '/planos',
  PONTOS_APOIO: '/pontos-apoio',
  RESUMO_COMPRA: '/resumo-compra',
  COMPRA_NOVO_PLANO: '/resumo-compra/novo-plano',
  RETIRADA_DEVOLUCAO: '/retirada-devolucao',
  VIAGEM_BLOQUEIO: '/viagem/bloqueio',
  RETIRADA_DEVOLUCAO_CONFIRMA: '/retirada-devolucao-confirma',
  ROOT: '/',
  SELECAO_PAGAMENTO: '/cadastro/selecao-pagamento',
  TROCAR_CARTAO: '/trocar-cartao',
  MIGRAR_PLANO_SOBRE: '/migrar-plano',
  WARNING_LIST_INFO: '/warning-list-info',
  PEDAL_RESPONSA: '/pedal-responsa',
  WARNING_LIST: '/warning-list',
  ...CONTA_CLIENTE,
} as const;

export const LINKS = {
  SUPPORT: `https://tembicihelp.zendesk.com/hc/pt-br/categories/${ZENDESK_ID}`,
};

type RoutesKeys = keyof typeof ROUTES;
export type AppRoute = typeof ROUTES[RoutesKeys];
