import { createContext } from 'react';
import { getItem, storeItem } from '../utils/localStorage';
import { STORAGE } from '../constants/storage.const';
import { useQueryParams } from '../hooks/useQueryParams';
import { REGIONS, Region, RegionsKeys } from '../constants/regions.const';
import { PARAMS } from '../constants/params.const';

export interface IDriverContext {
  publicId: string;
  region: string;
}

export const DriverContext = createContext<IDriverContext>({
  publicId: '',
  region: '',
});

export const useDriver = (): IDriverContext => {
  const params = useQueryParams();
  const publicId = params.get(PARAMS.PUBLIC_ID) || getItem(STORAGE.PUBLIC_ID) || '';

  const region = params.get(PARAMS.REGION) || getItem<Region>(STORAGE.LOCATION) || '';

  storeItem<string>(STORAGE.PUBLIC_ID, publicId);
  storeItem<string>(STORAGE.LOCATION, region);

  return {
    publicId,
    region,
  };
};
