import { ICoords } from '../interfaces/coords.interface';

type DynamicKey<Key extends string> = { [key in Key]: string };

/**
 * joins word list based on specific key
 * @param items item list to be iterated ([{baz: 'foo'}, {baz: 'bar'}, {baz: 'quz'}])
 * @param key attribute accessor 'baz'
 * @returns merged items into a human readable string ('foo, bar e quz')
 */
export const joinWords = <Key extends string>(items: DynamicKey<Key>[], key: Key): string =>
  items.reduce((joined, item, idx, arr) => {
    if (idx === 0) return item[key];
    const separator = idx !== arr.length - 1 ? ',' : ' e';

    return `${joined}${separator} ${item[key]}`;
  }, '');

export const formatUrl = (url: string, params?: string[]): string => {
  if (!params) return url;
  const matchParams = /{(\d+)}/g;
  const replacer = (match: string, index: number): string => params[index] ?? match;

  return url.replace(matchParams, replacer);
};

export const formatDate = (date: string): string => {
  return `${date.slice(4, 8)}-${date.slice(2, 4)}-${date.slice(0, 2)}`;
};

export function parseDate(date: string): string {
  return new Intl.DateTimeFormat('pt-BR').format(new Date(date));
}

export const clearMask = (masked: string): string => masked.replace(/[()/.\s-]/g, '');

export const namePattern = /^(\S+([ ]{1,}))+\S+/;

export const maskEmail = (email: string): string => {
  const [userAddress, domain] = email.split('@');
  return `${userAddress.substr(0, 3)}****${userAddress.substr(
    userAddress.length - 3,
    userAddress.length,
  )}@${domain}`;
};

export const setMaskedPhone = (phone: string): string => {
  const phonePattern = /^(\d{2})(\d{4,5})(\d{4})$/g;
  const matchedPhone = phonePattern.exec(phone.replace('+55', ''));
  if (!matchedPhone) return '';
  const [_, state, prefix, suffix] = matchedPhone;
  return `(${state[0]}*) ${'*'.repeat(prefix.length)} ${suffix}`;
};

export const breakMoney = (value: string): string[] => {
  const moneyPattern = /(\d+)[,.](\d+)/g;
  const matchedMoney = moneyPattern.exec(value);
  if (!matchedMoney) return [];
  const [_, faceValue, cents] = matchedMoney;
  return [faceValue, cents];
};

export const breakLines = (textContent: string): Array<string> => {
  const breakLinePattern = /\u2028\r\n|\r\n\u2028|\r\n|\r|\n|\u2028|<br>/;
  return textContent.split(breakLinePattern);
};

export const formatToCurrency = (value: number): string =>
  new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value);

export const maskCard = (value: string, char = '●●●● ', numbers = 3): string => {
  let charReturn = '';
  for (let c = 0; c < numbers; c++) {
    charReturn += char;
  }
  charReturn += value;
  return charReturn;
};

export const setMaskedDocument = (document: string): string => {
  const documentPattern = /^(\d{3})(\d{3})(\d{3})(\d{2})$/;
  const matchedDocument = documentPattern.exec(document);
  if (!matchedDocument) return '';
  const [_, start, middle, end, verifyNumber] = matchedDocument;
  return `${start}.${middle}.${end}-${verifyNumber}`;
};

export const formatTime = (time: number): string => {
  const padTwodigits = (n: number): string => (n < 10 ? `0${String(n)}` : String(n));

  const MINUTE = 60;
  const seconds = padTwodigits(time % MINUTE);
  const minutes = padTwodigits(Math.floor(time / MINUTE));
  return `${minutes}:${seconds}`;
};

export const openMaps = (coords: ICoords): string => {
  const { lat, lon } = coords;
  return `https://www.google.com/maps/dir/?api=1&destination=${lat},${lon}`;
};

export const sortByDesc = <T>(array: T[] | undefined, field: keyof T): T[] | null => {
  if (array === undefined) return null;
  return [...array].sort((a: T, b: T) => {
    if (a[field] > b[field]) return -1;
    if (a[field] < b[field]) return 1;
    return 0;
  });
};

export const sortByAsc = <T>(array: T[] | undefined, field: keyof T): T[] | null => {
  if (array === undefined) return null;
  return [...array].sort((a: T, b: T) => {
    if (a[field] < b[field]) return -1;
    if (a[field] > b[field]) return 1;
    return 0;
  });
};

export const formatPhone = (phoneNumber: string): string => {
  if (!phoneNumber) return '';
  const number = phoneNumber.replace(/\D/g, '');
  const hasCountryCode = number.length > 11;
  if (hasCountryCode) {
    const countryCode = number.substring(0, 2);
    const areaCode = number.substring(2, 4);
    const firstPart = number.substring(4, 9).replace(/\d/g, '*');
    const lastPart = number.substring(9);

    const phoneToShow = `+${countryCode} ${areaCode} ${firstPart} ${lastPart}`;
    return phoneToShow;
  }
  const areaCode = number.substring(0, 2);
  const firstPart = number.substring(2, 7).replace(/\d/g, '*');
  const lastPart = number.substring(7);

  const phoneToShow = `${areaCode} ${firstPart} ${lastPart}`;
  return phoneToShow;
};
